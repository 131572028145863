<template>
  <div>
    <Salary/>
  </div>
</template>

<script>
import Salary from '@/components/App/Team/Salary'

export default {
  name: 'SalaryPage',
  components: {
    Salary
  }
}
</script>

<style scoped>

</style>
